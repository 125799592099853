<template>
  <div>
    <div class="top-bar row p-3 pt-2">
      <div class="col-12 text-center">
        <a href="" class="float-left pt-1 text-white">
          <i
            class="fas fa-chevron-left "
            @click.prevent="$router.push('/referral')"
          ></i>
        </a>
        <h1>Pengaturan</h1>
      </div>
    </div>

    <div class="row mt-2 p-3 text-left" v-if="profile.id">
      <div class="col-12 col-lg-4 row">
        <h1 class="ml-3">Details</h1>
        <!-- <div class="float-right">
        </div> -->
      </div>
      <div class="col-12 col-lg-8 ">
        <div class="mt-1">
          <h6><b>Nama Depan</b></h6>
          <h6>{{ profile.namaDepan }}</h6>
        </div>
        <!-- <button
          type="button"
          class="btn btn-primary cardBtn"
          @click.prevent="$router.push('/profileEdit')"
          style="border-radius: 50px; background-color: #2f5884;
          border-color: none;
          right:0;
          position: absolute;"
        >
          <i class="fas fa-pen"></i>
        </button> -->

        <div class="mt-3">
          <h6><b>Nama Belakang</b></h6>
          <h6>{{ profile.namaBelakang }}</h6>
        </div>

        <div class="mt-3" v-if="profile.kategori">
          <h6><b>Kategori</b></h6>
          <h6>{{ profile.kategori }}</h6>
        </div>

        <div class="mt-3">
          <h6><b>Email</b></h6>
          <h6>{{ profile.email }}</h6>
        </div>

        <div class="mt-3 w-35">
          <h6><b>Telepon</b></h6>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Tipe</th>
                <th scope="col">Nomer</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ profile.telepon[0].tipe }}</td>
                <td>{{ profile.telepon[0].nomor }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="mt-3 w-35">
          <h6><b>Alamat</b></h6>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Tipe</th>
                <th scope="col">Nama</th>
                <th scope="col">jalan</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ profile.alamat[0].tipe | getAlamat }}</td>
                <td>{{ profile.alamat[0].nama }}</td>
                <td>{{ profile.alamat[0].jalan }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      <button
        type="submit"
        class="btn btn-primary mt-1 "
        @click.prevent="$router.push('/profileEdit')"
      >
        <h6 class="mb-0" v-if="!loading">
          Edit Profile
        </h6>
      </button>
      </div>
    </div>
    <hr />

    <div class="row p-3 text-left">
      <div class="col-12 col-lg-4">
        <h1>Ubah Password</h1>
      </div>
      <div class="col-12 col-lg-8">
        <div class="alert alert-success" role="alert" v-if="changedPass">
          Password Sudah Berubah
        </div>
        <form action="" class="w-35">
          <div class="form-group">
            <label for="exampleInputPassword1">Password Lama *</label>
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              required
              v-model="passLama"
            />
          </div>

          <div class="form-group">
            <label for="exampleInputPassword1">Password Baru *</label>
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              required
              v-model="passBaru"
            />
          </div>

          <div class="form-group">
            <label for="exampleInputPassword1"
              >Konfirmasi Password Baru *</label
            >
            <input
              type="password"
              class="form-control"
              id="exampleInputPassword1"
              placeholder="Password"
              required
              v-model="passBaruLagi"
            />
            <label v-if="passBaru !== passBaruLagi" class="text-danger"
              >Password Tidak Sama</label
            >
          </div>

          <button
            type="submit"
            class="btn btn-primary mt-1 "
            @click.prevent="changePassword"
            :disabled="passBaru !== passBaruLagi || passBaruLagi.length < 1"
          >
            <div
              class="spinner-border text-primary"
              role="status"
              v-if="loading"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <h6 class="mb-0" v-if="!loading">
              Simpan
            </h6>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar";
import axios from "@/axios";
import { mapState } from "vuex";
export default {
  name: "pengaturan",
  data() {
    return {
      profile: {},
      passLama: "",
      passBaru: "",
      passBaruLagi: "",
      changedPass: false,
      loading: false,
    };
  },
  components: {
    Navbar,
  },
  methods: {
    changePassword() {
      this.loading = true;
      axios
        .post(
          `/mandayauaa/api/account/change-password`,
          {
            currentPassword: this.passLama,
            newPassword: this.passBaru,
          },
          {
            headers: {},
          }
        )
        .then(({ data }) => {
          this.changedPass = true;
          this.loading = false;
          this.$toast.success("Password Berhasil Diubah");
        })
        .catch((err) => {
          this.loading = false;
          const title = err.response.data
           this.$toast.error(title.title);
        });
    },
    getEnum(val) {
      switch (val) {
        case "PASIEN_BELUM_MASUK":
          return "Pasien Belum Masuk";
        case "PASIEN_DATANG":
          return "Pasien Datang";
        case "DIRUJUK_KETEMPAT_LAIN":
          return "Dirujuk Ketempat Lain";
        case "DALAM_PERAWATAN":
          return "Dalam Perawatan";
        case "PASIEN_PULANG":
          return "Pasien Pulang";
        case "TIDAK_JADI_DATANG":
          return "Tidak Jadi Datang";
        case "DALAM_PROSES_PEMBAYARAN":
          return "Pasien Belum Masuk";
        case "SUDAH_TERBAYARKAN":
          return "Pasien Belum Masuk";
        case "CLOSED":
          return "Closed";
      }
    },
    currentregistrasi() {
      let token = localStorage.getItem("token");

      axios
        .get(`/api/currentregistrasi`, {
          headers: {},
        })
        .then(({ data }) => {
          this.profile = data;
          this.getKategoriId();
          // console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getKategoriId() {
      let token = localStorage.getItem("token");

      axios
        .get(`/api/kategoris/${this.profile.kategoriId}`)
        .then(({ data }) => {
          this.profile.kategori = data.nama;
          this.$store.commit("SET_PROFILE_USER", this.profile);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.currentregistrasi();
  },
  filters: {
    getAlamat(val) {
      switch (val) {
        case "KLINIK":
          return "Klinik";
        case "KERJA":
          return "Kantor";
        case "RUMAH":
          return "Rumah";
        case "PRAKTEK_PRIBADI":
          return "Praktek Pribadi";
        case "RUMAH_BERSALIN":
          return "Rumah bersalin";
        case "RUMAH_SAKIT":
          return "Rumah sakit";
        default:
          return '-'
        }
        
    }
  }
};
</script>

<style></style>
